<template>
  <div class="min-h-screen bg-gray-100 relative">
    <!-- 顶部导航栏 -->
    <nav class="bg-blue-600 text-white p-4">
      <div class="container mx-auto flex justify-end space-x-6">
        <a href="https://apifox.chatgptten.com" class="hover:text-blue-200">接口文档</a>
        <a href="https://mj.chatgptten.com/token" class="hover:text-blue-200">令牌管理</a>
        <a href="https://mj.chatgptten.com/topup" class="hover:text-blue-200">余额充值</a>
        <a href="https://mj.chatgptten.com/setting?tab=personal" class="hover:text-blue-200">个人中心</a>
      </div>
    </nav>

    <!-- 主要内容 -->
    <main class="container mx-auto mt-12 px-4">
      <!-- Logo 和标题 -->
      <div class="text-center mb-8">
        <h1 class="text-5xl font-bold mb-4">智言AI API</h1>
        <h2 class="text-3xl font-semibold text-blue-600 mb-4">多种大模型一站式集成</h2>
        <p class="text-lg text-gray-600 mb-4">
          智言 API提供各大AI模型接口集合管理服务，仅用一个接口即可体验不同平台的100+大模型
        </p>
        <p class="text-sm text-gray-500">
          低价格 / 高并发 / 无次数限制
        </p>
        <p class="text-sm text-blue-600 font-semibold">
          本站部分模型，充值比例为 每充0.38元兑换1美元，按需充值，虚拟商品不支持退款
        </p>
      </div>

      <!-- 操作按钮 -->
      <div class="flex flex-wrap justify-center gap-4 mb-12">
        <button class="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300">
          日API调用量 100K+
        </button>
        <button class="px-6 py-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition duration-300">
          支持大模型数 100+
        </button>
        <button class="px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300">
          注册会员数 120K+
        </button>
        <button class="px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-300">
          充值比例 1CNY : 1USD
        </button>
        <button class="px-6 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-600 transition duration-300">
          合作用户充值比例 累计满500刀 8.5折
        </button>
      </div>


    </main>

    <!-- 帮助按钮 -->
    <div class="fixed bottom-4 right-4">
      <button class="w-12 h-12 bg-pink-500 text-white rounded-full flex items-center justify-center text-2xl hover:bg-pink-600 transition duration-300">
        ?
      </button>
    </div>

    <!-- 公告模态框 -->
    <div v-if="showModal" class="fixed inset-0 z-50 overflow-auto flex items-center justify-center p-4">
      <div class="absolute inset-0 bg-black opacity-10"></div>
      <div class="relative bg-white bg-opacity-70 backdrop-blur-sm w-full max-w-2xl m-auto flex-col flex rounded-lg shadow-lg overflow-hidden">
        <div class="bg-gradient-to-r from-green-500 to-red-500 text-white font-bold py-2 px-4 text-center">
          最新公告
        </div>
        <div class="text-sm space-y-4 overflow-y-auto max-h-[70vh] p-8">
          <p class="font-semibold">🎉 Oai/AZ混合线路：</p>
          <p>超高并发，支持识图、函数调用、流式秒回，0.38 元起</p>
          <p class="font-semibold">🎉 3.5逆向线路：</p>
          <p>高并发，0.1 元起</p>
          <p>还不会使用的API的小伙伴可以查看→<span class="text-blue-600 cursor-pointer"><a href="https://apifox.chatgptten.com/" class="text-blue-600 hover:underline">基础教程</a></span>，新用户可购买一次卡网6元10刀测试卡密~，限购一次~再次提醒各位用户注意额度的有效期~</p>
          <p>GPTs，4all 部分模型限时5折~具体请查看→<span class="text-blue-600 cursor-pointer"><a href="https://news.chatgptten.com/zhi-yan-ai-zhi-chi-de-quan-bu-mo-xing-lie-biao/" class="text-blue-600 hover:underline">模型倍率</a></span></p>
          <p>欢迎使用智言 API，欢迎各大站主对接~联系客服解决您的疑问~会员政策更新请查看最新→</p>
          <p class="font-bold">🎉 智言 VIP客户只需累计消费满 500$，就可以获得8.5折优惠！ </p>
          <p>🎉 邀请返利：使用邀请码邀请一名新用户可立刻获得5$，您所邀请的用户每次充值，您都可获得充值的10%<span class="text-blue-600 cursor-pointer">  <a href="https://mj.chatgptten.com/setting?tab=personal" class="text-blue-600 hover:underline">查看邀请链接</a></span></p>
          <p>🎯兑换码特惠：→<span class="text-blue-600 cursor-pointer"> <a href="https://chat.chatgptten.com/dist/" class="text-blue-600 hover:underline">购买兑换码</a> </span></p>
          <p>🔘如您有任何想法、问题，或需要购买 AI 大模型，请联系客服 QQ：419644560（务必备注用户名）</p>
          <p>🔘出现"quota"字样请检查账户余额是否充足（余额有效期是否过期，若过期，请充值任意数额刷新或联系客服重置），有任何问题请咨询客服解决~</p>
          <p>🔘请各位用户自检接口内容，严禁一切违法违规的请求。在大陆地区运营要遵守《生成式人工智能管理办法》相关规定。</p>
          <p>🔘本站用户面向科研，您可以用来获取信息，违规内容会做敏感词过滤处理~请勿上传个人信息，上传个人信息者会做封号处理！</p>
        </div>
        <span class="absolute top-2 right-2">
          <button @click="closeModal" class="text-white hover:text-gray-200">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPageWithModal',
  data() {
    return {
      showModal: true
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
    }
  }
}
</script>