<template>
  <div class="image-gallery">
    <div class="gallery-container" ref="galleryContainer">
      <div
          v-for="(image, index) in displayedImages"
          :key="index"
          class="image-item"
          :style="{ width: `${image.width}px`, height: `${image.height}px` }"
          @click="openModal(image)"
      >
        <div v-if="image.isLoading" class="loading" v-html="image.placeholder" />
        <img v-else :src="image.url" :alt="image.alt" loading="lazy" />
      </div>
    </div>
    <div v-if="isLoading" class="loading-indicator">加载中...</div>

    <!-- 修改后的模态框 -->
    <transition name="fade">
      <div class="modal" v-if="showModal" @click.self="closeModal" :class="{ 'fullscreen': isFullscreen }"
           @touchstart="handleTouchStart" @touchend="handleTouchEnd"
           tabindex="0" @keydown="handleKeyDown">
        <div class="modal-content">
          <img :key="selectedImage.url" :src="selectedImage.url" :alt="selectedImage.alt" />
          <button role="button" @click="closeModal" class="close-btn" aria-label="关闭">
            <svg class="close-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
          <button class="nav-btn prev-btn" @click="showPreviousImage" aria-label="上一张">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
          <button class="nav-btn next-btn" @click="showNextImage" aria-label="下一张">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
          <button class="fullscreen-btn" @click="toggleFullscreen" aria-label="全屏">
            <svg v-if="!isFullscreen" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
            </svg>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [],
      containerWidth: 0,
      displayedImages: [],
      currentPage: 1,
      imagesPerPage: 12,
      hasMoreImages: true,
      showModal: false,
      selectedImage: {},
      selectedImageIndex: -1,
      isMobile: false,
      isLoading: false,
      isFullscreen: false,
      loadingImage: 'https://chat.chatgptten.com/screenshots/img/file/5e1d6da814ed571df43ea.gif?e=1735488000&token=P7S2Xpzfz11vAkASLTkfHN7Fw-oOZBecqeJaxypL:c0UmjI3eFCYBiQtE1Jb_8u7CkkQ=',
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  mounted() {
    this.checkMobile();
    this.setContainerWidth();
    this.loadImages();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setContainerWidth() {
      this.containerWidth = this.$refs.galleryContainer.offsetWidth;
    },
    loadImages() {
      if (this.isLoading || !this.hasMoreImages) return;

      this.isLoading = true;
      fetch(`https://chat.chatgptten.com/u/global_users/artwork/resource/urls?_page=${this.currentPage}&_limit=${this.imagesPerPage}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.length < this.imagesPerPage) {
              this.hasMoreImages = false;
            }
            this.processImages(data);
            this.images.push(...data);
            this.displayedImages = this.images;
            this.currentPage++;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error('Failed to fetch images:', error);
            this.isLoading = false;
          });
    },
    processImages(images) {
      const columns = this.isMobile ? 2 : 4;
      const gapSize = 16;
      const availableWidth = this.containerWidth - (columns - 1) * gapSize;
      const imageWidth = Math.floor(availableWidth / columns);

      images.forEach((image) => {
        image.isLoading = true;
        image.width = imageWidth;
        image.height = Math.floor(imageWidth / image.ratio);
        image.placeholder = `<div style="width: ${image.width}px; height: ${image.height}px; background-color: #eee;"></div>`;

        const img = new Image();
        img.onload = () => {
          image.isLoading = false;
          this.$forceUpdate();
        };
        img.onerror = () => {
          image.isLoading = false;
          this.$forceUpdate();
        };
        img.src = image.url;
      });
    },
    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.offsetHeight;
      const threshold = 200; // pixels from bottom

      if (scrollPosition >= documentHeight - threshold && !this.isLoading && this.hasMoreImages) {
        this.loadImages();
      }
    },
    openModal(image) {
      this.selectedImage = image;
      this.selectedImageIndex = this.images.findIndex(img => img.url === image.url);
      this.showModal = true;
      document.body.style.overflow = 'hidden';
      this.$nextTick(() => {
        this.$el.querySelector('.modal').focus();
      });
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = '';
    },
    showPreviousImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
        this.selectedImage = { ...this.images[this.selectedImageIndex] };
      }
    },
    async showNextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
        this.selectedImage = { ...this.images[this.selectedImageIndex] };
      } else if (this.hasMoreImages) {
        await this.loadNextPage();
        if (this.images.length > this.selectedImageIndex + 1) {
          this.selectedImageIndex++;
          this.selectedImage = { ...this.images[this.selectedImageIndex] };
        }
      }
    },
    async loadNextPage() {
      if (this.isLoading || !this.hasMoreImages) return;

      this.isLoading = true;
      try {
        const response = await fetch(`https://chat.chatgptten.com/u/global_users/artwork/resource/urls?_page=${this.currentPage}&_limit=${this.imagesPerPage}`);
        const data = await response.json();

        if (data.length < this.imagesPerPage) {
          this.hasMoreImages = false;
        }
        this.processImages(data);
        this.images.push(...data);
        this.displayedImages = this.images;
        this.currentPage++;
      } catch (error) {
        console.error('加载下一页图片失败:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleResize() {
      this.checkMobile();
      this.setContainerWidth();
      this.processImages(this.images);
      this.$forceUpdate();
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$el.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    fullscreenChangeHandler() {
      this.isFullscreen = !!document.fullscreenElement;
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleSwipe();
    },
    handleSwipe() {
      const swipeThreshold = 50;
      if (this.touchEndX < this.touchStartX - swipeThreshold) {
        this.showNextImage();
      } else if (this.touchEndX > this.touchStartX + swipeThreshold) {
        this.showPreviousImage();
      }
    },
    handleKeyDown(event) {
      switch (event.key) {
        case 'ArrowLeft':
          this.showPreviousImage();
          break;
        case 'ArrowRight':
          this.showNextImage();
          break;
        case 'Escape':
          this.closeModal();
          break;
      }
    },
  },
};
</script>

<style scoped>
.image-gallery {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.image-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-item:hover img {
  transform: scale(1.05);
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #666;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  outline: none;
}

.modal-content {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1001;
}

.close-btn svg {
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  fill: #e74c3c;
}

.close-btn:hover svg {
  fill: #c0392b;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-content::-webkit-scrollbar {
  width: 10px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading img {
  max-width: 100%;
  max-height: 100%;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1001;
}

.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.prev-btn {
  left: 20px;
}

.next-btn {
  right: 20px;
}

.nav-btn svg {
  display: block;
}


.fullscreen-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1001;
}

.fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.fullscreen-btn svg {
  display: block;
  width: 20px;
  height: 20px;
}

.modal.fullscreen {
  background-color: black;
}

.modal.fullscreen .modal-content {
  width: 100%;
  height: 100%;
}

.modal.fullscreen img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>