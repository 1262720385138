<template>
  <div class="container mx-auto py-8">
    <div class="mb-4 text-center">
      <h1 class="text-3xl font-bold text-gray-900 mb-4">智言 AI 绘画</h1>
    </div>

    <div class="mb-4">
      <input
          type="text"
          class="border border-gray-400 rounded-full px-4 py-2 w-full text-lg bg-gray-200 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="输入提示词"
          v-model="prompt"
      />
    </div>

    <div class="flex justify-center items-center mb-4">
      <button
          class="bg-gray-200 hover:bg-gray-300 text-gray-900 font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400 mr-2"
          @click="generateImage"
      >
        生成图像
      </button>
      <button
          class="bg-gray-200 hover:bg-gray-300 text-gray-900 font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400"
          @click="showAdvancedOptions"
      >
        高级模式
      </button>
    </div>

    <!-- Aspect Ratio Selection -->
    <div class="mb-4">
      <h3 class="text-gray-900 text-xl mb-1">长宽比</h3>
      <div class="flex flex-wrap gap-2 mt-1">
        <button
            v-for="(ratio, index) in aspectRatios"
            :key="index"
            :class="{
            'selected': selectedAspectRatio === ratio,
            'default': selectedAspectRatio !== ratio,
          }"
            class="text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            @click="selectAspectRatio(ratio)"
        >
          <span class="text-lg">{{ ratio }}</span>
        </button>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
      <div v-for="(option, index) in options" :key="index" class="bg-white rounded-lg p-4 shadow-md">
        <label class="text-gray-900 text-lg mb-2 block">{{ option.label }}</label>
        <select
            class="border border-gray-400 rounded-full px-4 py-2 w-full text-lg bg-gray-200 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
            v-model="option.value"
            @change="option.changed = true"
        >
          <option v-for="(item, i) in option.items" :key="i" :value="item.value">
            {{ item.label }}
          </option>
        </select>
      </div>
    </div>

    <!-- Modal -->
    <div v-show="showModal" class="modal fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center show">
      <div class="modal-content bg-white rounded-lg p-8 relative" style="max-width: 90%; max-height: 90%;">
        <svg
            class="absolute top-2 right-2 cursor-pointer"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="closeModal"
        >
          <path d="M18 6L6 18M6 6l12 12"></path>
        </svg>
        <h2 class="text-2xl text-gray-900 mb-4">图像生成</h2>

        <div v-if="!generatedImages.length" class="flex items-center justify-center">
          <img src="https://chat.chatgptten.com/screenshots/img/file/5e1d6da814ed571df43ea.gif?e=1735488000&token=P7S2Xpzfz11vAkASLTkfHN7Fw-oOZBecqeJaxypL:c0UmjI3eFCYBiQtE1Jb_8u7CkkQ="
               alt="正在加载图像">
        </div>

        <div v-else class="grid grid-cols-2 gap-4">
          <div v-for="(image, index) in generatedImages" :key="index" class="flex items-center justify-center">
            <img :src="image" alt="生成的图像" @load="showModal = true" class="max-w-full max-h-full">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      generatedImages: [],
      showModal: false,
      selectedAspectRatio: null,
      prompt: "",
      aspectRatios: ["1:1", "2:3", "3:4", "9:16", "3:2", "4:3", "16:9"],
      options: [
        {
          label: "绘画类型",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "漫画", value: "漫画"},
            {label: "油画", value: "油画"},
            {label: "水彩画", value: "水彩画"},
            {label: "中国水墨画", value: "中国水墨画"},
            {label: "铅笔画", value: "铅笔画"},
            {label: "炭笔画", value: "炭笔画"},
            {label: "粉笔画", value: "粉笔画"},
            {label: "水粉画", value: "水粉画"},
            {label: "壁画", value: "壁画"},
            {label: "珐琅画", value: "珐琅画"},
            {label: "数字绘画", value: "数字绘画"},
            {label: "素描", value: "素描"},
            {label: "线条画", value: "线条画"},
            {label: "儿童绘画", value: "儿童绘画"},
          ],
        },
        {
          label: "光线强度",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "强光", value: "强光"},
            {label: "柔光", value: "柔光"},
            {label: "阴影", value: "阴影"},
            {label: "明暗对比", value: "明暗对比"},
            {label: "逆光", value: "逆光"},
            {label: "光影交错", value: "光影交错"},
          ],
        },
        {
          label: "画质强化",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "细节丰富", value: "细节丰富"},
            {label: "色彩饱满", value: "色彩饱满"},
            {label: "清晰锐利", value: "清晰锐利"},
            {label: "磨砂质感", value: "磨砂质感"},
            {label: "立体感强烈", value: "立体感强烈"},
            {label: "真实感十足", value: "真实感十足"},
            {label: "笔触生动", value: "笔触生动"},
          ],
        },
        {
          label: "艺术风格",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "赛博朋克", value: "赛博朋克"},
            {label: "写实主义", value: "写实主义"},
            {label: "超现实主义", value: "超现实主义"},
            {label: "梦幻", value: "梦幻"},
            {label: "抽象表现主义", value: "抽象表现主义"},
            {label: "幻想主义", value: "幻想主义"},
            {label: "表现主义", value: "表现主义"},
            {label: "前卫艺术", value: "前卫艺术"},
            {label: "极简主义", value: "极简主义"},
            {label: "印象派", value: "印象派"},
          ],
        },
        {
          label: "视角",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "一人称", value: "一人称"},
            {label: "鸟瞰", value: "鸟瞰"},
            {label: "虫瞰", value: "虫瞰"},
            {label: "侧面", value: "侧面"},
            {label: "仰角", value: "仰角"},
            {label: "俯角", value: "俯角"},
            {label: "倾斜", value: "倾斜"},
            {label: "近景", value: "近景"},
            {label: "全景", value: "全景"},
          ],
        },
        {
          label: "相机",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "单反相机拍摄风格", value: "单反相机拍摄风格"},
            {label: "中画幅相机拍摄风格", value: "中画幅相机拍摄风格"},
            {label: "数码相机拍摄风格", value: "数码相机拍摄风格"},
            {label: "拍立得拍摄风格", value: "拍立得拍摄风格"},
            {label: "无人机拍摄风格", value: "无人机拍摄风格"},
          ],
        },
        {
          label: "场景类型",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "城市风光", value: "城市风光"},
            {label: "乡村田园", value: "乡村田园"},
            {label: "宫殿建筑", value: "宫殿建筑"},
            {label: "星空夜景", value: "星空夜景"},
            {label: "海洋风景", value: "海洋风景"},
            {label: "山水景观", value: "山水景观"},
            {label: "雪山冰川", value: "雪山冰川"},
            {label: "自然风光", value: "自然风光"},
            {label: "工业厂区", value: "工业厂区"},
            {label: "古堡遗址", value: "古堡遗址"},
            {label: "现代建筑", value: "现代建筑"},
          ],
        },
        {
          label: "渲染引擎",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "Blender", value: "Blender"},
            {label: "Maya", value: "Maya"},
            {label: "3ds Max", value: "3ds Max"},
            {label: "Cinema4D", value: "Cinema4D"},
            {label: "V-Ray", value: "V-Ray"},
            {label: "Arnold", value: "Arnold"},
            {label: "Redshift", value: "Redshift"},
            {label: "Octane Render", value: "Octane Render"},
          ],
        },
        {
          label: "材质",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "金属", value: "金属"},
            {label: "玻璃", value: "玻璃"},
            {label: "水", value: "水"},
            {label: "石头", value: "石头"},
            {label: "木材", value: "木材"},
            {label: "塑料", value: "塑料"},
            {label: "纱绸", value: "纱绸"},
            {label: "皮革", value: "皮革"},
            {label: "云彩", value: "云彩"},
            {label: "星光", value: "星光"},
          ],
        },
        {
          label: "修饰词",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "高雅", value: "高雅"},
            {label: "可爱", value: "可爱"},
            {label: "精致", value: "精致"},
            {label: "锐化", value: "锐化"},
            {label: "磨砂", value: "磨砂"},
            {label: "闪光", value: "闪光"},
            {label: "霓虹", value: "霓虹"},
            {label: "电影效果", value: "电影效果"},
            {label: "柔美", value: "柔美"},
            {label: "华丽", value: "华丽"},
            {label: "生动", value: "生动"},
            {label: "极简", value: "极简"},
            {label: "浪漫", value: "浪漫"},
          ],
        },
        {
          label: "色调",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "复古褐", value: "复古褐"},
            {label: "暖色调", value: "暖色调"},
            {label: "冷色调", value: "冷色调"},
            {label: "鲜艳色彩", value: "鲜艳色彩"},
            {label: "黑白灰", value: "黑白灰"},
            {label: "单色调", value: "单色调"},
            {label: "鲜艳色彩", value: "鲜艳色彩"},
            {label: "原色", value: "原色"},
            {label: "淡雅色彩", value: "淡雅色彩"},
            {label: "鲜花色彩", value: "鲜花色彩"},
            {label: "柔和色彩", value: "柔和色彩"},
          ],
        },
        {
          label: "提高画质",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "8K分辨率", value: "8K分辨率"},
            {label: "高画质细节", value: "高画质细节"},
            {label: "纹理丰富", value: "纹理丰富"},
            {label: "高光表现", value: "高光表现"},
            {label: "真实阴影", value: "真实阴影"},
            {label: "色彩还原度高", value: "色彩还原度高"},
            {label: "光线逼真", value: "光线逼真"},
            {label: "高清晰度", value: "高清晰度"},
            {label: "画面流畅", value: "画面流畅"},
            {label: "线条清晰", value: "线条清晰"},
            {label: "自然柔和的阴影", value: "自然柔和的阴影"},
            {label: "高级抗锯齿", value: "高级抗锯齿"},
            {label: "物体细节精细", value: "物体细节精细"},
            {label: "高级环境渲染", value: "高级环境渲染"},
            {label: "真实光照效果", value: "真实光照效果"},
            {label: "高级纹理渲染", value: "高级纹理渲染"},
            {label: "景深效果突出", value: "景深效果突出"},
            {label: "模拟真实光线的光线追踪", value: "模拟真实光线的光线追踪"},
            {label: "高质量的反射和折射效果", value: "高质量的反射和折射效果"},
          ],
        },
        {
          label: "设计类型",
          value: "",
          changed: false,
          items: [
            {label: "无", value: ""},
            {label: "Logo设计", value: "Logo设计"},
            {label: "海报设计", value: "海报设计"},
            {label: "DM单页", value: "DM单页"},
            {label: "易拉宝", value: "易拉宝"},
            {label: "工业设计", value: "工业设计"},
            {label: "网页设计", value: "网页设计"},
            {label: "3D模型", value: "3D模型"},
            {label: "海报画册", value: "海报画册"},
            {label: "插画设计", value: "插画设计"},
          ],
        },
      ],
    };
  },

  methods: {
    async generateImage() {
      this.showModal = true;
      this.generatedImages = []; // 清空之前的图片

      // Split user input into prompt and parameters
      const parts = this.prompt.trim().split(/\s+(?=(?:--\w+))/);

      // Identify parameters
      const parameters = parts.filter(part => part.startsWith('--'));
      // Remove parameters from parts array
      const promptParts = parts.filter(part => !part.startsWith('--'));

      // Concatenate prompt with selected options
      const optionsString = this.options.reduce((acc, option) => {
        if (option.changed && option.value) {
          option.changed = false;
          return acc + " " + option.value;
        }
        return acc;
      }, "");

      // Add aspect ratio to the parameters array if selected
      if (this.selectedAspectRatio) {
        parameters.push(`--ar ${this.selectedAspectRatio}`);
      }

      // Combine all parameters
      let finalPrompt = promptParts.join(" ") + " " + optionsString;
      if (parameters.length > 0) {
        finalPrompt += " " + parameters.join(" ");
      }

      try {
        // Print the final prompt to the console
        console.log("Sending prompt:", finalPrompt);

        // Send request to image creator endpoint
        const response = await fetch('https://chat.chatgptten.com/u/global_users/thumbnail/images/creator', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({prompt: finalPrompt})
        });
        const data = await response.json();

        // Print the API response to the console
        console.log("API response:", data);

        if (data.success && data.data) {
          const taskId = data.data;

          // Poll for task completion
          let isTaskComplete = false;
          while (!isTaskComplete) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second
            const queryResponse = await fetch(`https://chat.chatgptten.com/u/global_users/thumbnail/images/query/${taskId}`);
            const queryData = await queryResponse.json();

            // Print the query response to the console
            console.log("Query response:", queryData);

            if (queryData.success && queryData.data.state === "已完成") {
              this.generatedImages = queryData.data.result;
              console.log("generatedImages:", this.generatedImages);
              isTaskComplete = true;
            }
          }
        } else {
          console.error("Image generation failed:", data.msg);
        }
      } catch (error) {
        console.error("Error during image generation:", error);
      }
    },
    selectAspectRatio(ratio) {
      this.selectedAspectRatio = ratio;
    },
    closeModal() {
      this.showModal = false;
      this.generatedImages = [];
    },
    showAdvancedOptions() {
      window.open('https://chat.chatgptten.com/midjourney', '_self');
    }
  },
};
</script>

<style scoped>
body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

input {
  border-radius: 8px;
  border: 2px solid #ddd;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  white-space: nowrap;
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: flex;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  padding-right: 15px;
  scroll-behavior: smooth;
  font-size: 16px;
  color: #222;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: #f59e0b;
  color: rgb(13, 13, 13);
}

.default {
  background-color: #2563eb;
  color: white;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.flex.justify-center {
  display: flex;
  justify-content: center;
}

.flex.items-center {
  display: flex;
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}

@media (max-width: 640px) {
  .flex.justify-center {
    flex-direction: row;
    flex-wrap: wrap;
  }

  button {
    margin-bottom: 0.5rem;
    width: calc(50% - 0.25rem);
  }
}

/* Close Button Style */
svg.cursor-pointer {
  width: 24px;
  height: 24px;
  fill: #333;
}

svg.cursor-pointer path {
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #333;
}
</style>