<template>
  <div class="ppsecure-page p-4">
    <h2 class="text-2xl font-bold text-gray-800 mb-6">欢迎使用未来的智能AI 应用端登录服务</h2>
    <p class="mb-4 text-sm text-gray-600">
      <a href="https://chat.chatgptten.com/" class="text-blue-500 hover:underline">首页</a> / 当前位置
    </p>
    <p class="space-x-4">
      <a href="https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/" class="text-blue-500 hover:underline">登录</a>
      <a @click="goToRegister" class="link text-blue-500 cursor-pointer hover:underline">注册</a>
      <a href="https://chat.chatgptten.com/" class="text-blue-500 hover:underline">首页</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  methods: {
    goToRegister() {
      this.$router.push('/register');
    }
  }
}
</script>

<style scoped>
.ppsecure-page {
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.link {
  color: #1a73e8; /* 设置注册链接颜色 */
}
</style>
