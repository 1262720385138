<template>
  <div class="centered-content">
    <div class="centered-content-inner">
      <div class="title">请扫码联系客服同学</div>
      <img src="/images/weixin.png" alt="二维码活动码">
    </div>
  </div>
</template>

<style scoped>
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.centered-content-inner {
  display: flex;
  flex-direction: column; /* 将子元素垂直排列 */
  align-items: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中 */
}

.title {
  font-size: 36px;
}

.centered-content img {
  width: 200px;
  height: 200px;
}
</style>
