<template>
  <h1 class="title">智言AI</h1>
  <div class="password-reset-form">
    <h2>密码重置</h2>
    <form @submit.prevent="resetPassword">
      <div class="form-group">
        <label for="oldPassword">旧密码</label>
        <input type="password" id="oldPassword" v-model="oldPassword" required/>
      </div>
      <div class="form-group">
        <label for="newPassword">新密码</label>
        <input type="password" id="newPassword" v-model="newPassword" required/>
      </div>
      <div class="form-group">
        <label for="confirmPassword">确认密码</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" required/>
      </div>
      <div v-if="passwordMismatch" class="error">密码不一致。</div>
      <div v-if="passwordError" class="error">{{ passwordError }}</div>
      <button type="submit" :disabled="isSubmitting">{{ isSubmitting ? '提交中...' : '重置密码' }}</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordMismatch: false,
      passwordError: '',
      isSubmitting: false,
    };
  },
  methods: {
    getUid() {
      const uid = localStorage.getItem('uid');
      console.log("password ... uid ... ", uid)
      return uid;
    },
    resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.passwordMismatch = true;
        return;
      }

      // 基本密码安全性检查（根据需要自定义）

      this.passwordMismatch = false;
      this.passwordError = '';
      this.isSubmitting = true;

      const id = this.getUid();
      axios
          .post('https://chat.chatgptten.com/u/global_users/passwdReset', {
            id,
            newPassword: this.newPassword,
            oldPassword: this.oldPassword, // 在请求中包含旧密码
          })
          .then((response) => {
            if (response.data.success) {
              // 密码重置成功
              // 显示成功消息或执行其他操作
              console.info("123看看能不能获取到setting vue的uid ... ... ...", id)
              console.info("response.data ... ... ...", response.data)
              console.info("密码修改成功")
              this.$router.push('/setting');

            } else {
              console.info("456看看能不能获取到setting vue的uid ... ... ...", id)
              // 根据响应代码处理服务器响应
              if (response.data.code === '50000') {
                this.passwordError = '用户不存在。';
              } else {
                this.passwordError = '密码重置失败。';
              }
              this.isSubmitting = false;
            }
          })
          .catch((error) => {
            console.info("789看看能不能获取到setting vue的uid ... ... ...", id)
            console.error('密码重置失败：', error);
            this.passwordError = '密码重置失败。';
            this.isSubmitting = false;
          });
    },
  },
};
</script>


<style scoped>

.title {
  text-align: center;
  color: #1976d2; /* 可以调整颜色以匹配页面的主题色 */
  font-size: 36px; /* 根据页面的大小调整标题的大小 */
  font-weight: bold; /* 使标题字体加粗 */
  margin-bottom: 30px; /* 在标题下方增加一些空间 */
  font-family: Roboto, Helvetica, Arial, sans-serif; /* 与其他文本保持一致的字体 */
}

.password-reset-form {
  width: 300px;
  margin: 0 auto;
}

.password-reset-form {
  width: 300px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.error {
  color: #d32f2f;
}
</style>
