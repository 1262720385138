<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import './assets/css/tailwind.min.css'

export default {
  name: "App",
};
</script>

<style>
@import "~vuetify/dist/vuetify.min.css";
</style>