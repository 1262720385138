<template>
  <div class="flex justify-center items-center h-screen bg-gray-100 font-sans">
    <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
      <h1 class="text-3xl font-bold mb-6 text-blue-600 text-center">创建帐户</h1>
      <form @submit.prevent="register" class="space-y-4">
        <div>
          <label for="username" class="block text-gray-700 font-medium mb-1">账号</label>
          <input id="username" v-model="username"
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                 placeholder="用户名"/>
        </div>
        <div>
          <label for="password" class="block text-gray-700 font-medium mb-1">密码</label>
          <input id="password" type="password" v-model="password"
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"/>
        </div>
        <!--        <div>-->
        <!--          <label for="confirmPassword" class="block text-gray-700 font-medium mb-1">确认密码</label>-->
        <!--          <input id="confirmPassword" type="password" v-model="confirmPassword"-->
        <!--                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"/>-->
        <!--        </div>-->

        <div style="display: none">
          <label for="email" class="block text-gray-700 font-medium mb-1">邮箱</label>
          <div class="flex">
            <input id="email" v-model="email"
                   class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                   placeholder="电子邮箱"/>
            <button type="button"
                    class="ml-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 whitespace-nowrap"
                    @click="sendVerificationCode" :disabled="isButtonDisabled" :class="{ 'opacity-50 cursor-not-allowed': isButtonDisabled }">验证
            </button>
          </div>
          <div v-if="codeSuccessTip" class="text-green-500 mt-2">{{ codeSuccessTip }}</div>
        </div>

        <div style="display: none">
          <label for="code" class="block text-gray-700 font-medium mb-1">验证码</label>
          <input id="code" v-model="code"
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                 placeholder="验证码" maxlength="10"/>
        </div>

        <div>
          <label for="invitationCode" class="block text-gray-700 font-medium mb-1">邀请码</label>
          <input id="invitationCode" v-model="invitationCode"
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                 placeholder="可以不填" maxlength="4"/>
        </div>
        <button type="submit"
                class="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                :disabled="isButtonDisabled" :class="{ 'opacity-50 cursor-not-allowed': isButtonDisabled }">
          免费注册
        </button>
      </form>
      <div v-if="errorMessage" class="text-red-500 mt-4 text-center">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "RegisterPage",
  data() {
    return {
      username: "",
      password: "",
      // confirmPassword: "",
      invitationCode: "",
      code: "",
      email: "419644560@qq.com",
      errorMessage: "",
      codeSuccessTip: "", // 新增的提示信息
      retryCount: 5,
      isButtonDisabled: false,
    };
  },
  created() {
    if (this.$route.query.iCode) {
      this.invitationCode = this.$route.query.iCode;
    }
  },
  methods: {
    register() {
      const usernameRegex = /^[a-zA-Z0-9_-]{2,50}$/; // 匹配包含字母、数字、下划线或短横线的用户名，长度在2到50个字符之间
      if (!usernameRegex.test(this.username)) {
        alert("用户名必须包含8到50个字符，只能包含字母、数字、下划线(_)或短横线(-)");
        return;
      }
      if (this.password.length < 1 || this.password.length > 50) {
        alert("密码长度必须在8到50个字符之间");
        return;
      }
      // if (!/[A-Z]/.test(this.password) || !/[a-z]/.test(this.password) || !/[0-9]/.test(this.password)) {
      //   alert("密码必须包含至少一个大写字母、一个小写字母和一个数字");
      //   return;
      // }
      // if (this.password !== this.confirmPassword) {
      //   alert("两次密码输入不一致");
      //   return;
      // }
      // if (!this.code) {
      //   alert("请输入验证码");
      //   return;
      // }
      // 获取当前时间
      const currentDate = new Date();

// 设置过期时间为一个月后
      currentDate.setMonth(currentDate.getMonth() + 1);

// 格式化过期时间为 ISO 字符串，并去掉毫秒部分
      const expirationTime = currentDate.toISOString().split('.')[0] + 'Z';

      const apiUrl = "https://chat.chatgptten.com/u/global_users";

      const requestBody = {
        loginName: this.username,
        password: this.password,
        invitationCode: this.invitationCode,
        isActive: 1,
        tag: "gpt4,mj,gpt4v",
        expirationTime: expirationTime,
        code: this.code,
        email: this.email,
      };
      this.isButtonDisabled = true;
      axios.post(apiUrl, requestBody)
          .then((response) => {
            if (response.data.success) {
              this.redirectToExternalPage();
            } else {
              this.errorMessage = response.data.msg;
              console.error("注册失败:", response.data.msg);
              this.retryCount--;
              if (this.retryCount > 0) {
                this.register();
              }
            }
          })
          .catch((error) => {
            this.errorMessage = "注册请求失败，请稍后重试";
            console.error("注册请求失败:", error);
          })
          .finally(() => {
            this.isButtonDisabled = false;
          });
    },

    redirectToExternalPage() {
      const router = this.$router;
      if (router) {
        window.location.href = 'https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/';
      } else {
        console.error("Router对象未定义。");
      }
    },
    sendVerificationCode() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(this.email)) {
        alert("必须符合电子邮件格式");
        return;
      }
      const apiUrl = "https://chat.chatgptten.com/u/global_users/send-verification-email";
      const requestBody = new URLSearchParams();
      requestBody.append("email", this.email);
      this.isButtonDisabled = true;
      axios
          .post(apiUrl, requestBody, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            if (response.data.success) {
              this.codeSuccessTip = "验证码已发送，如果没有收到邮件，请检查垃圾箱。"; // 在这里添加提示信息
              console.log("验证码发送成功");
            } else {
              this.errorMessage = response.data.msg;
              console.error("验证码发送失败:", response.data.msg);
            }
          })
          .catch((error) => {
            this.errorMessage = "验证码发送请求失败，请稍后重试";
            console.error("验证码发送请求失败:", error);
          })
          .finally(() => {
            this.isButtonDisabled = false;
          });
    },
  },
};
</script>