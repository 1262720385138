<template>
  <h1 class="title">智言AI</h1>
  <div class="form-container">
    <form @submit.prevent="submit" class="login-form">
      <div class="form-group">
        <label for="authorizationCode">授权码</label>
        <input id="authorizationCode" v-model="authorizationCode" class="form-element" name="authorizationCode" />
      </div>
      <button type="submit" class="home-button primary form-button" :disabled="isButtonDisabled">{{ buttonText }}</button>
<!--      <button @click="goToRegister" class="home-button secondary form-button">注册</button>-->
    </form>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close-button" @click="closeModal">×</span>
        <p>{{ modalMessage }}</p>
        <div class="centered-content" style="width: 200px; height: 200px;">
          <img src="/images/qcode.jpg" alt="二维码活动码" style="width: 100%; height: 100%;">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "CodeLoginPage",
  data() {
    return {
      authorizationCode: "", // Added authorizationCode
      showModal: false,
      modalMessage: "",
      isButtonDisabled: false,
      buttonText: '登录',
      retryCount: 0,
      // Fixed values for username and password
      username: "test",
      password: "test",
    };
  },
  methods: {
    submit() {
      if (this.authorizationCode === "") {
        return;
      }
      this.isButtonDisabled = true;
      this.buttonText = "登录中...";
      const apiUrl = "https://chat.chatgptten.com/user/login";
      const requestBody = {
        loginName: this.username,
        password: this.password,
        authorizationCode: this.authorizationCode, // Added authorizationCode to the request body
      };

      this.login(apiUrl, requestBody);
    },
    login(apiUrl, requestBody) {
      axios.post(apiUrl, requestBody)
          .then((response) => {
            if (response.data.success) {
              this.handleLoginSuccess(response);
            } else {
              this.handleLoginFailure(response);
            }
          })
          .catch((error) => {
            this.handleLoginFailure(error.response);
          })
          .finally(() => {
            this.isButtonDisabled = false;
            this.buttonText = "登录";
          });
    },
    handleLoginSuccess(response) {
      const token = response.data.data.token;
      const uid = response.data.data.uid;
      const expiration = response.data.data.expiration;
      const tag = response.data.data.tag;

      localStorage.setItem("token", token);
      localStorage.setItem("uid", uid);
      localStorage.setItem("expiration", expiration);
      localStorage.setItem("tag", tag);

      this.showModal = true;

      if (response.data.code == 'WARNING') {
        this.modalMessage = response.data.message;
        this.showModal = true;
      } else if (response.data.code == 'EXPIRED') {
        this.modalMessage = response.data.message;
        this.showModal = true;
      } else {
        this.$router.push("/");
      }
    },
    handleLoginFailure(response) {
      this.retryCount += 1;
      if (this.retryCount < 5) {
        // 在重试之前更新按钮文本
        this.buttonText = "登录中...";
        const apiUrl = "https://chat.chatgptten.com/user/login";
        const requestBody = {
          loginName: this.username,
          password: this.password,
        };
        this.login(apiUrl, requestBody);
      } else {
        if (response && response.data && response.data.message) {
          this.modalMessage = response.data.message;
        } else {
          this.modalMessage = "登录失败，请稍后重试";
        }

        this.showModal = true;
        console.error("登录请求失败:", response);

        this.isButtonDisabled = false;
        this.buttonText = "登录";
      }
    },

    goToRegister() {
      this.$router.push("/register");
    },
    closeModal() {
      this.showModal = false;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.form-group {
  margin-bottom: 20px;
}

.form-element {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 28px;
  box-sizing: border-box;
}

.home-button {
  background: transparent;
  cursor: pointer;
  border: none;
  border-radius: 28px;
  font-size: 18px;
  padding: 16px 24px;
  margin-bottom: 20px;
  color: #fff;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.primary {
  background-color: #1976d2;
}

.home-button:hover,
.home-button:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.home-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  color: #1976d2; /* 可以调整颜色以匹配页面的主题色 */
  font-size: 36px; /* 根据页面的大小调整标题的大小 */
  font-weight: bold; /* 使标题字体加粗 */
  margin-bottom: 30px; /* 在标题下方增加一些空间 */
  font-family: Roboto, Helvetica, Arial, sans-serif; /* 与其他文本保持一致的字体 */
}
/* ... */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  color: #aaa;
  position: absolute;
  top: 10px; /* 添加这一行 */
  right: 10px; /* 修改这一行 */

  font-size: 28px;
  font-weight: bold;
}


.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.secondary {
  background-color: #4caf50;
}

.secondary:hover {
  background-color: rgba(76, 175, 80, 0.1);
}
</style>
