<template>
  <div>
    <video
        ref="videoPlayer"
        class="video-js vjs-big-play-centered"
    ></video>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const videoPlayer = ref(null)
    let player = null

    const initializePlayer = () => {
      if (player) {
        player.dispose()
      }

      player = videojs(videoPlayer.value, props.options, () => {
        console.log('Video player is ready')
        player.on('error', (error) => {
          console.error('Video player error:', error)
        })
      })
    }

    onMounted(() => {
      initializePlayer()
    })

    onBeforeUnmount(() => {
      if (player) {
        player.dispose()
      }
    })

    watch(() => props.options, () => {
      initializePlayer()
    }, { deep: true })

    return {
      videoPlayer
    }
  }
}
</script>

<style scoped>
.video-js {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
</style>