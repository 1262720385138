<template>
  <div class="flex h-screen bg-gray-100">
    <!-- Sidebar -->
    <aside class="w-64 bg-gray-800 text-white p-4">
      <div class="mb-8">
        <img src="https://gw.chatgptten.com/icons/icon-192x192.png" alt="Logo" class="w-12 h-12 mb-2" />
        <h1 class="text-xl font-bold">Open API 开放平台</h1>
      </div>
      <nav>
        <ul>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">数据报表</a></li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">调用统计</a></li>
          <li class="mb-2">
            <a href="#" class="hover:text-gray-300 flex items-center justify-between">
              日志
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
            </a>
          </li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300 text-blue-400">模型列表</a></li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">API密钥</a></li>
          <li class="mb-2">
            <a href="#" class="hover:text-gray-300 flex items-center justify-between">
              个人中心
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
            </a>
          </li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">工单系统</a></li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">推广返佣</a></li>
          <li class="mb-2"><a href="#" class="hover:text-gray-300">数据大屏</a></li>
        </ul>
      </nav>
    </aside>

    <!-- Main Content -->
    <main class="flex-1 flex flex-col">
      <header class="bg-white shadow-sm p-4">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <button class="mr-4">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            </button>
            <input type="text" placeholder="搜索..." class="px-4 py-2 border rounded-lg" />
          </div>
          <nav>
            <ul class="flex items-center">
              <li class="mr-4"><a href="#">首页</a></li>
              <li class="mr-4"><a href="#">在线客服</a></li>
              <li class="mr-4"><a href="#">联系我们</a></li>
              <li>
                <button class="flex items-center">
                  <img src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="User" class="w-8 h-8 rounded-full mr-2" />
                  <span>普通用户</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <!-- Content Area (iframe) -->
      <div class="flex-1">
        <iframe :src="iframeUrl" class="w-full h-full border-none" title="Content"></iframe>
      </div>

      <footer class="bg-white p-4 text-center text-gray-500">
        <p>Copyright © 2024 Open API 开放平台 All Rights Reserved. Load time: 0.02s</p>
      </footer>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iframeUrl: 'https://example.com/content-page' // Replace with your actual content URL
    }
  }
}
</script>