<template>
  <div class="bg-gray-900 h-screen">
    <!-- 星星背景 -->
    <div class="absolute top-0 left-0 w-full h-full">
      <div v-for="n in 200" :key="n" class="absolute top-[randomY] left-[randomX] w-1 h-1 rounded-full bg-yellow-300 opacity-50"></div>
      <div v-for="n in 100" :key="n" class="absolute top-[randomY] left-[randomX] w-1 h-1 rounded-full bg-pink-300 opacity-50"></div>
    </div>
    <!-- 内容 -->
    <div class="flex flex-col items-center justify-center h-full px-4">
      <!-- Logo -->
      <img src="https://sdxlturbo.ai/_next/static/media/logo.58e19c56.webp" alt="LobeChat Logo" class="w-20 h-20 mb-8">
      <!-- 标题 -->
      <h1 class="text-5xl font-bold text-white leading-tight">
        Built for you.<br>
        the Super Individual
      </h1>
      <!-- 副标题 -->
      <p class="text-lg text-gray-300 mt-4 text-center">
        在 LobeChat 中将你的 AI 团队汇聚一处：根据个性化需求灵活<br>
        定制智能助手功能，解决问题，提升生产, 探索未来工作模式
      </p>
      <!-- Cloud 标签 -->
      <span class="inline-block bg-yellow-400 text-gray-800 font-semibold rounded-full px-4 py-1 mt-4 text-sm">Cloud</span>
    </div>
    <div class="bg-gray-100 rounded-lg shadow-lg p-8 mt-16 w-full max-w-screen-md mx-auto">
      <h2 class="text-2xl font-bold text-gray-800 mb-4">解锁个人潜能：AI 工具伙伴</h2>
      <p class="text-gray-600 mb-6">
        打造你的个人 AI 助手团队，与 AI 携手共进，创造卓越事业。无论是日常生活、学习还是创意工作，
        AI 都能助你一臂之力，让你事半功倍。在个性化定制的时代，你就是焦点，这就是 LobeChat 中的现实。
      </p>
      <!-- 按钮组 -->
      <div class="flex justify-between">
        <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          立即订阅 >
        </button>
        <button class="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded">
          社区反馈
        </button>
      </div>
      <!-- 价格信息 -->
      <div class="flex justify-between mt-8">
        <div class="text-center">
          <span class="text-cyan-500 text-xl font-bold">Free</span><br>
          <span class="text-gray-500 text-sm">开放使用</span>
        </div>
        <div class="text-center">
          <span class="text-orange-500 text-xl font-bold">240+</span><br>
          <span class="text-gray-500 text-sm">助手功能</span>
        </div>
        <div class="text-center">
          <span class="text-yellow-500 text-xl font-bold">55+</span><br>
          <span class="text-gray-500 text-sm">语言模型</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-16 px-4">
      <!-- 卡片 1 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f1.webp" alt="Card 1 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">任何问题，任何地点</h3>
        <p class="text-gray-600">不分时空，不论主题，无论是在电脑前还是移动端，助手一直都在。</p>
      </div>
      <!-- 卡片 2 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f2.webp" alt="Card 2 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">轻松上手，宛如聊天</h3>
        <p class="text-gray-600">体验宛如寻常聊天软件般自在，轻松切换助手，享受有序的对话乐趣。</p>
      </div>
      <!-- 卡片 3 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f3.webp" alt="Card 3 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">立刻拥有，私人 AI</h3>
        <p class="text-gray-600">丰富的助手市场，简单易用的助手编辑器，创建 AI 团队，不费吹灰之力。</p>
      </div>
      <!-- 卡片 4 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f4.webp" alt="Card 4 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">能够看见，也能看懂</h3>
        <p class="text-gray-600">不知道如何描述？没问题，上传图片，让视觉认知模型为你解读万象。</p>
      </div>
      <!-- 卡片 5 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f5.webp" alt="Card 5 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">能够听见，亦能讲话</h3>
        <p class="text-gray-600">语音对话轻松开启，还可自选助手音色，让交流更富个性。</p>
      </div>
      <!-- 卡片 6 -->
      <div class="card">
        <img src="https://hub-apac-1.lobeobjects.space/landing/overview/f8.webp" alt="Card 6 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">各域插件，无所不能</h3>
        <p class="text-gray-600">无论是学术、金融还是游戏，丰富插件让你助手能力全面升级。</p>
      </div>
    </div>

    <div class="mt-16 px-4 bg-gray-100 rounded-lg shadow-lg p-8 mx-auto max-w-screen-md">
      <h2 class="text-2xl font-bold text-gray-800 mb-4 text-center">
        正在改变数千万用户的工具流
      </h2>
      <p class="text-lg text-gray-600 mb-8 text-center">
        Powered by
      </p>
      <div class="flex flex-wrap justify-center gap-4">
        <!-- 合作伙伴 Logo -->
        <img src="https://chat.chatgptten.com/screenshots/img/file/2813d60c08d67a5c9e1ae.png" alt="GPT-3.5" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/1e8d1280f605f3db13b65.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/f3540f4a096ca0c6f3c24.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/f0bd5218ab84bb9069712.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/a2151998ab2c3f875f822.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/e9075e6853a31944199d3.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/2ef61cae13fffab4118f5.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/71bce823aeca6ae9b3e1f.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/5789b4f3489fd1bd33d75.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/9b053549093be2376b85c.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/4b4e2791cfdb242c42d7b.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/86a96171e97142488dbe5.png" alt="" class="h-8">
        <img src="https://chat.chatgptten.com/screenshots/img/file/7687abad2768bb74f7d2e.png" alt="" class="h-8">
        <!-- ... 添加其他 Logo ... -->
      </div>
    </div>


    <!-- 新的卡片区域 -->
    <div class="grid grid-cols-2 gap-4 mt-16 px-4">
      <!-- 卡片 1 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/ce651c0ce0169bc775f62.png" alt="Card 1 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">从构思到图文并茂: 你的私人小编</h3>
        <p class="text-gray-600">利用搜索插件，轻松搜索资料、整理素材</p>
        <p class="text-gray-600">多款绘画插件，为文章配图提供最佳作图模型</p>
        <p class="text-gray-600">一键生成文案并搭配精美配图</p>
        <p class="text-gray-600">快速重新生成内容，满足您的要求</p>
      </div>
      <!-- 卡片 2 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/8808d1859c0ddfd326c64.png" alt="Card 2 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">轻松掌握全球语言: 你的个人学习伴侣</h3>
        <p class="text-gray-600">上传 PDF，与你学习资料直接对话</p>
        <p class="text-gray-600">学术助手为你搜索科学文献</p>
        <p class="text-gray-600">定制学习助手，满足个性化学习偏好</p>
        <p class="text-gray-600">实现外语翻译并朗读</p>
      </div>
      <!-- 卡片 3 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/60da0c44cb242e2d719ac.png" alt="Card 3 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">能够看见，也能看懂</h3>
        <p class="text-gray-600">不仅能识别图片内容，还能理解、上传、描述，让机器帮你解决视觉难题。</p>
      </div>
      <!-- 卡片 4 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/653a5fb3f6bf1736ca842.png" alt="Card 4 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">编程无忧: 你的全栈开发助手</h3>
        <p class="text-gray-600">网站爬虫帮助你快速得到类型定义</p>
        <p class="text-gray-600">一键补充 Typescript 类型注释</p>
        <p class="text-gray-600">自动生成单元测试</p>
        <p class="text-gray-600">一键生成项目 Readme</p>
      </div>
      <!-- 卡片 5 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/3cdb102b1a3493b111293.png" alt="Card 5 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">立竿见影，私人 AI</h3>
        <p class="text-gray-600">丰富预制的助手，简单易用的助手编辑器，创建属于自己的 AI 团队，不费吹灰之力。</p>
      </div>
      <!-- 卡片 6 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/a586150a439894916f457.png" alt="Card 6 Image" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">无缝链接，无所不能</h3>
        <p class="text-gray-600">无缝衔接各大主流软件，丰富插件让你的助手能力全面升级。</p>
      </div>
    </div>

    <div class="mt-16 px-4 bg-gray-100 rounded-lg shadow-lg p-8 mx-auto max-w-screen-md">
      <h2 class="text-2xl font-bold text-gray-800 mb-4 text-center">
         超过 240+ 助手
      </h2>
      <p class="text-lg text-gray-600 mb-8 text-center">
        为您解决各领域专业问题
      </p>
      <div class="flex flex-wrap justify-center gap-4">
        <!-- 合作伙伴 Logo -->
        <!-- ... 保留现有 Logo ... -->
        <!-- ... 添加其他 Logo ... -->
      </div>
    </div>

    <div class="grid grid-cols-3 gap-4 mt-16 px-4">
      <!-- 卡片 1 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/efd0afd1ba06fbf3e67a7.png" alt="图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">检索大师</h3>
        <p class="text-gray-600">检索助手</p>
        <p class="text-gray-600">@jillzhang</p>
      </div>
      <!-- 卡片 2 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/878f665bc4e7fc8eca367.png" alt="图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">搭建GPT</h3>
        <p class="text-gray-600">一个基于开源模型的AI, 通过简洁的对话方式, 帮你解决问题, 精神内耗终结者</p>
        <p class="text-gray-600">@AlConductor</p>
      </div>
      <!-- 卡片 3 -->
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/a5f48f7932b2e023dc938.png" alt="图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">API文档助手</h3>
        <p class="text-gray-600">想看啥API文档, 问我就行, 自动帮你查找</p>
        <p class="text-gray-600">@arvinxx</p>
      </div>
      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/a5f48f7932b2e023dc938.png" alt="会计助手" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">会计助手</h3>
        <p class="text-gray-600">会计分录，为全体个人和企业提供全面的会计支持与税务知识。</p>
        <p class="text-gray-600">@MYSeaIT</p>
      </div>

      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/efd0afd1ba06fbf3e67a7.png" alt="强大的价格" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">强大的价格</h3>
        <p class="text-gray-600">详细的硬币信息价格（来自 https://github.com/danielmiesler/fabric）</p>
        <p class="text-gray-600">@pedrospecial101</p>
      </div>

      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/878f665bc4e7fc8eca367.png" alt="老爸，吃了么？" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">老爸，吃了么？</h3>
        <p class="text-gray-600">一个始终挂念孩子生活方方面面的老爸，从小生活琐事，大事到工作烦恼。</p>
        <p class="text-gray-600">@aihoom</p>
      </div>

      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/a5f48f7932b2e023dc938.png" alt="时钟图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">创业计划</h3>
        <p class="text-gray-600">在几分钟内生成详细的全面商业计划</p>
        <p class="text-gray-600">@canismirror1990</p>
      </div>

      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/878f665bc4e7fc8eca367.png" alt="电脑图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">前端研发架构师</h3>
        <p class="text-gray-600">擅长架构，技术调研，擅长复杂项目架构方案</p>
        <p class="text-gray-600">@arvinxx</p>
      </div>

      <div class="card">
        <img src="https://chat.chatgptten.com/screenshots/img/file/878f665bc4e7fc8eca367.png" alt="放大镜图标" class="w-full rounded-lg mb-4">
        <h3 class="text-lg font-bold text-gray-800 mb-2">食谱生成器</h3>
        <p class="text-gray-600">试着命名一道菜，我会告诉你方法。</p>
        <p class="text-gray-600">@lgroshka</p>
      </div>
      <!-- ... 添加其他卡片 ... -->
    </div>


  </div>
</template>

<script>
export default {
  methods: {
    randomY() {
      return Math.random() * 100 + '%'
    },
    randomX() {
      return Math.random() * 100 + '%'
    },
  },
}
</script>

<style scoped>
.card {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.card:hover {
  background-color: #e0e0e0;
}

h1, h2, h3 {
  font-family: 'Open Sans', sans-serif;
}

p {
  line-height: 1.6;
}

/* 合作伙伴 Logo 样式 */
.flex img {
  filter: grayscale(100%); /* 添加灰度效果 */
  opacity: 0.8; /* 降低透明度 */
  transition: filter 0.3s, opacity 0.3s; /* 添加过渡效果 */
}

.flex img:hover {
  filter: grayscale(0%);
  opacity: 1;
}
</style>